import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  email: string = '';

  constructor(
    private router: Router,
    private appService: AppService
  ) {}

  ngOnInit() {
  }

  reset() {
    if (this.email == '') { return alert('missing information'); }

    this.appService.forgot(this.email)
    .then(result => {
      alert('please check your email');
    })
    .catch(err => alert(err.statusText));
  }
}
