import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { HotkeyModule } from 'angular2-hotkeys';
import { MomentModule } from 'ngx-moment';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppGlobals } from './app.globals';
import { LoginComponent } from './login/login.component';
import { SuperadminDashboardComponent } from './superadmin-dashboard/superadmin-dashboard.component';
import { NgbdModalContentPositionDescription, UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { UserGuard } from './user.guard';
import { TellerGuard } from './teller.guard';
import { AdminGuard } from './admin.guard';
import { SuperadminGuard } from './superadmin.guard';
import { HomeComponent } from './home/home.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DepartmentViewComponent } from './department-view/department-view.component';
import { DepartmentGuard } from './department.guard';
import { OrderModule } from 'ngx-order-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { PayoutComponent } from './payout/payout.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserDashboardComponent,
    SuperadminDashboardComponent,
    NotfoundComponent,
    HomeComponent,
    ForgotComponent,
    ResetPasswordComponent,
    DepartmentViewComponent,
    PayoutComponent,
    NgbdModalContentPositionDescription
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    NgbModule,
    ToastrModule.forRoot(),
    HotkeyModule.forRoot(),
    MomentModule,
    OrderModule,
    FilterPipeModule,
    AppRoutingModule,
  ],
  providers: [
    AppGlobals,
    { provide: APP_INITIALIZER, useFactory: globalsProviderFactory, deps: [ AppGlobals ], multi: true },
    UserGuard,
    TellerGuard,
    AdminGuard,
    SuperadminGuard,
    DepartmentGuard
  ],
  entryComponents: [
    NgbdModalContentPositionDescription
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function globalsProviderFactory(provider: AppGlobals) { return () => provider.load(); }