import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export function crypt (salt, text) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  
    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
};
  
export function decrypt (salt, encoded) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
};

@Injectable()
export class AppGlobals {
    public bank: any = null;
    public user: any = null;
    public volunteering: any = null;

    constructor(
        private http: HttpClient
    ) { }

    load() {
        return new Promise((resolve, reject) => {
            let key = this.getParameterByName('k');
            if (key == undefined) {
                let helper = new JwtHelperService();
                if (localStorage.getItem('token')) {
                    let token = localStorage.getItem('token');
                    if (!helper.isTokenExpired(token)) {
                        this.user = helper.decodeToken(token).data;
                    } else {
                        this.user = null;
                    }
                }
                console.log(this.user);
                resolve(null);
            } else {
                this.http.post('/api/login', { 'key': key })
                .toPromise<any>()
                .then(result => {
                    let helper = new JwtHelperService();
                    localStorage.setItem('token', result['token']);
                    this.user = helper.decodeToken(result['token']).data;
                    console.log(this.user);
                    resolve(null);
                })
                .catch(err => {
                    reject(err);
                });
            }
        })
        .then(() => {
            return this.http.get('/api/config.json', {
                headers: new HttpHeaders({
                  'Content-Type': 'application/json'
                })
            })
            .toPromise()
            .then(result => {
                this.bank = result['bank'];
                this.volunteering = result['volunteering'];
            });
        })
        .catch(err => alert(err.error || err.statusText || err));
    }

    getParameterByName(name) {
        var url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return undefined;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

}
