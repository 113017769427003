import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HotkeysService } from 'angular2-hotkeys';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals, crypt } from '../app.globals';
import { AppService } from '../app.service';
import { ActivatedRoute, convertToParamMap } from '@angular/router';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap, switchMap, catchError } from 'rxjs/operators';
import QRCode from 'qrcode';

@Component({
  selector: 'app-department-view',
  templateUrl: './department-view.component.html',
  styleUrls: ['./department-view.component.css']
})
export class DepartmentViewComponent implements OnInit {

  isDepartmentMgr: boolean = false;
  departmentRole: any = 0;
  department: any;
  new: any = {
    date: '',
    start: '',
    duration: '',
    pay: 0,
    user: null,
    checkin: null,
    checkout: null,
    paid: 0
  }
  accountSearchResults: any = [];
  searching = false;
  newUser: any = {
    user: null,
    role: 0
  };
  filter: any = {};
  departmentUsers: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private hotkeysService: HotkeysService,
    private toastrService: ToastrService,
    public appGlobals: AppGlobals,
    private appService: AppService
  ) { }

  filterStaffList(filter:any) {
    if (filter == "!0")
      this.departmentUsers = this.department.users.filter(o => o.role != 0);
    else
      this.departmentUsers = this.department.users.filter(o => o.role == filter);
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.appService.getDepartment(params.get("department")).then(result => {
        this.department = result;
        this.isDepartmentMgr = this.appGlobals.user.departments.find(o => o.id === this.department.id).role >= 1;
        this.departmentRole = this.appGlobals.user.departments.find(o => o.id === this.department.id).role;
        this.filterStaffList("!0");
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    });

  }

  getDateRange() {
    return this.appGlobals.volunteering.dates.split("|");
  }

  addSchedule() {
    this.appService.addDepartmentSchedule(this.activatedRoute.snapshot.params.department, this.new).then(result => {
      this.department.schedule.push(result);
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }

  updateSchedule(item: any) {
    if (item.tip > 5)
      item.tip = 5;
    if (item.tip < 0)
      item.tip = 0;
    this.appService.updateDepartmentSchedule(this.activatedRoute.snapshot.params.department, item).then(result => {
      this.toastrService.success("saved");
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }

  removeSchedule(item: any) {
    if (confirm(`Are you sure you want to remove this schedule?`)) {
      this.appService.removeDepartmentSchedule(this.activatedRoute.snapshot.params.department, item.id).then(result => {
        this.toastrService.success("removed");
        this.department.schedule.splice(this.department.schedule.indexOf(item), 1);
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    }
  }
  checkin(item: any) {
    let user = this.department.users.find(o => o.id == item.user);
    if (confirm(`Are you sure you want to check-in '${user.firstname} ${user.lastname}`)) {
      item.checkin = new Date();
      this.updateSchedule(item);
    }
  }

  checkout(item: any) {
    let user = this.department.users.find(o => o.id == item.user);
    if (confirm(`Are you sure you want to check-out '${user.firstname} ${user.lastname}`)) {
      item.checkout = new Date();
      this.updateSchedule(item);
    }
  }

  pay(item: any) {
    let user = this.department.users.find(o => o.id == item.user);
    if (confirm(`Are you sure you want to pay '${user.firstname} ${user.lastname}`)) {
      this.appService.payDepartmentSchedule(this.department.id, item.id, parseInt(item.pay || 0) + parseInt(item.tip || 0)).then(result => {
        this.toastrService.success("paid");
        item.paid = 1;
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    }
  }

  userSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter(o => o.trim().length >= 2),
      tap(() => this.searching = true),
      switchMap(term =>
        this.appService.listUsers(convertToParamMap({ search: term.trim() })).pipe(
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  formatter = (x: { id: number, firstname: string, lastname: string, nickname: string, email: string }) => {
    return `${x.firstname} ${x.lastname} (${x.nickname})`
  };
  addUser() {
    this.appService.addDepartmentUser(this.department.id, this.newUser.user.id, this.newUser.role).then(result => {
      this.department.users.push(result);
      this.toastrService.success("Added");
      this.newUser.user = null;
      this.newUser.role = 0;
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }
  updateUser(user: any) {
    this.appService.updateDepartmentUser(this.department.id, user).then(result => {
      this.toastrService.success("saved");
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }
  removeUser(user: any) {
    if (confirm(`Are you sure you want to pay '${user.firstname} ${user.lastname}`)) {
      this.appService.removeDepartmentUser(this.department.id, user.id).then(result => {
        this.toastrService.success("removed");
        this.department.users.splice(this.department.users.indexOf(user), 1);
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    }
  }
  async download() {
    var encrypt = (a) => a.toString().replace(/1/g,"q").replace(/2/g,"w").replace(/3/g,"e").replace(/4/g,"r").replace(/5/g,"t").replace(/6/g,"y").replace(/7/g,"u").replace(/8/g,"i").replace(/9/g,"o").replace(/0/g,"p").replace(/-/g,"a");

    var dates = [...new Set(this.department.schedule.map(a => a.date))].sort((a:string,b:string) => a.localeCompare(b));
    var retVal = "<html><body>";

    for (let date of dates) {
      for (let row of this.department.schedule.filter(a => a.date == date).sort((a,b) => a.start.localeCompare(b.start))) {
        retVal += `
        <div style="break-inside:avoid-page;">
          <div style="display:flex;flex-direction:row;">
            <div style="display:flex;margin-right:20px;align-items:center;flex-direction:column;">
              <img src="${await QRCode.toDataURL("http://volunteer.redprick.local/payout?id=" + encrypt(row.id.toString() + "a" + row.department.toString() + "a" + (Date.parse(row.created)/1000)))}"/>
              ${encrypt(row.id.toString() + "a" + row.department.toString() + "a" + (Date.parse(row.created)/1000)).toString().toUpperCase()}
            </div>
            <div style="width:100%;margin-bottom:50px;">
              <table cellpadding="0" cellspacing="0" width="100%">
                <tr>
                  <td width="25%"><b>Date</b></td>
                  <td width="25%" style="text-align:center;"><b>Start</b></td>
                  <td width="25%" style="text-align:center;"><b>Duration</b></td>
                  <td width="25%" style="text-align:right;"><b>Pay</b></td>
                </tr>
                <tr>
                  <td>${row.date}</td>
                  <td style="text-align:center;">${((row.start.split(":")[0] + 11) % 12 + 1)}:${row.start.split(":")[1]} ${row.start.split(":")[0] < 12 ? "AM":"PM"}</td>
                  <td style="text-align:center;">${row.duration}</td>
                  <td style="text-align:right;">${row.pay}</td>
                </tr>
                <tr>
                  <td colspan="2"><b>Position</b></td>
                  <td colspan="2" style="text-align:right;"><b>Volunteer</b></td>
                </tr>
                <tr>
                  <td colspan="2">${row.position}</td>
                  <td colspan="2" style="text-align:right;">${(this.department.users.find(u => u.id == row.user) || {}).nickname || ''}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <hr>`;
      }
    }
    retVal += "</body></html>";
    /*
    var retVal = "<html><body>" + dates.map(date => this.department.schedule.filter(a => a.date == date).sort((a,b) => a.start.localeCompare(b.start)).map((row: any) => `
      <div style="break-inside:avoid-page;">
        <div style="display:flex;flex-direction:row;">
          <div style="display:flex;margin-left:20px;margin-right:20px;align-items:center;">
            <img src="${QRCode.toDataURL(encrypt(row.id.toString() + "a" + row.department.toString() + "a" + (Date.parse(row.created)/1000)))}"/><!--
            <svg class="barcode"
              jsbarcode-format="code39"
              jsbarcode-value="${encrypt(row.id.toString() + "a" + row.department.toString() + "a" + (Date.parse(row.created)/1000))}"
              jsbarcode-textmargin="0"
              jsbarcode-fontoptions="bold"
              jsbarcode-width="1"
              jsbarcode-height="50">
            </svg>-->
          </div>
          <div style="width:100%;margin-bottom:50px;">
            <table cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td width="25%"><b>Date</b></td>
                <td width="25%" style="text-align:center;"><b>Start</b></td>
                <td width="25%" style="text-align:center;"><b>Duration</b></td>
                <td width="25%" style="text-align:right;"><b>Pay</b></td>
              </tr>
              <tr>
                <td>${row.date}</td>
                <td style="text-align:center;">${row.start}</td>
                <td style="text-align:center;">${row.duration}</td>
                <td style="text-align:right;">${row.pay}</td>
              </tr>
              <tr>
                <td colspan="2"><b>Position</b></td>
                <td colspan="2" style="text-align:right;"><b>Volunteer</b></td>
              </tr>
              <tr>
                <td colspan="2">${row.position}</td>
                <td colspan="2" style="text-align:right;">${(this.department.users.find(u=>u.id == row.user) || {}).nickname || ''}</td>
              </tr>
            </table>
            <!--<div style="display:flex;flex-direction:row;justify-content:space-between;">
              <div><b>Date:</b> ${row.date}</div>
              <div><b>Pay:</b> ${row.pay}</div>
            </div>
            <div style="display:flex;flex-direction:row;justify-content:space-between;">
              <div><b>Start Time:</b> ${row.start}</div>
              <div><b>Duration:</b> ${row.duration}</div>
            </div>
            <div style="display:flex;flex-direction:row;justify-content:space-between;">
              <div><b>Position:</b> ${row.position}</div>
              <div><b>Volunteer:</b> ${(this.department.users.find(u=>u.id == row.user) || {}).nickname || ''}</div>
            </div>-->
          </div>
        </div>
      </div>`).join("<hr>")).join("<hr>\r\n") + `<hr><script src="${window.location.origin}/assets/JsBarcode.all.min.js"></script><script type="text/javascript">JsBarcode(".barcode").init();</script></body></html>`;*/
    var fileData = new Blob([retVal], {type: 'text/html'});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //for IE
      window.navigator.msSaveOrOpenBlob(fileData, `${this.department.name}.html`);
    } else { // for other browsers (chrome, Firefox, Etc.)}
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = 'none';
        var pdfUrl = URL.createObjectURL(fileData);
        a.href = pdfUrl;
        a.download = `${this.department.name}.html`;
        a.click();
        URL.revokeObjectURL(a.href);
        a.remove();
    }
  }
}
