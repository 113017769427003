import { Component, OnInit } from '@angular/core';
import { AppGlobals } from '../app.globals';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {

  constructor(
    private appGlobals: AppGlobals,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.router.url == '/' && this.appGlobals.user != null) {
      if (this.appGlobals.user.role == 0) {
        this.router.navigate(['/overview']);
      } else if (this.appGlobals.user.role == 1) {
        this.router.navigate(['/teller']);
      } else if (this.appGlobals.user.role == 2) {
        this.router.navigate(['/admin']);
      } else if (this.appGlobals.user.role == 99) {
        this.router.navigate(['/admin']);
      }
    }
  }

}
