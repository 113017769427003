import { Component, Input, OnInit } from '@angular/core';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter, switchMap, catchError, tap, timeout } from 'rxjs/operators';
import { convertToParamMap } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{item.position}} Description</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{item.description}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `
})
export class NgbdModalContentPositionDescription {
  @Input() item;

  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  schedule: any = [];
  available: any = [];
  availableFilter: any = {};
  
  constructor(
    private toastrService: ToastrService,
    public appGlobals: AppGlobals,
    private appService: AppService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.appService.getUserSchedule(this.appGlobals.user.id)
      .then(results => this.schedule = results)
      .catch(err => alert(err));
    this.appService.getAvailableSchedules()
      .then(results => this.available = results)
      .catch(err => this.toastrService.error(err));
  }

  getDateRange() {
    return this.appGlobals.volunteering.dates.split("|");
  }

  showPositionDescription(item) {
    const modalRef = this.modalService.open(NgbdModalContentPositionDescription);
    modalRef.componentInstance.item = item;
  }

  getDepartments() {
    return this.available.filter((value, index, self) => {
      return self.findIndex(o => o.name == value.name) === index;
    }).map(o => o.name);
  }

  volunteer(item: any) {
    if (!confirm('Are you sure you want to volunteer for this position?'))
      return;
    this.appService.volunteerSchedule(item.id, this.appGlobals.user.id)
    .then(results => {
      this.available.splice(this.available.indexOf(item), 1);
      item.user = this.appGlobals.user.id;
      this.schedule.push(item);
      this.toastrService.success('You have volunteered!');
    })
    .catch(err => this.toastrService.error(err.message || err));
  }

  buildIcs() {
    console.log(`BEGIN:VCALENDAR
    VERSION:2.0
    CALSCALE:GREGORIAN
    PRODID:-//Redprick//Volunteering//EN
    METHOD:REQUEST
    ${this.schedule.map(item => `BEGIN:VEVENT
    CLASS:PRIVATE
    CREATED:${Date.now()}
    DESCRIPTION:${item.position}
    DTSTART:${Date.now()}
    DTEND:${Date.now()}
    DTSTAMP:${Date.now()}
    LOCATION:${item.name}
    SEQUENCE:0
    STATUS:CONFIRMED
    SUMMARY:${item.description}
    TRANSP:OPAQUE
    UID:${item.id}
    END:VEVENT`).join("\r\n")}
    END:VCALENDAR`);
  }
}
