import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.css']
})
export class PayoutComponent implements OnInit {
  error: string = '';
  code: string = "";

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private appService: AppService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit() { 
    document.getElementsByName("payoutCode")[0].focus();
    if (new URL(window.location.href).searchParams.has("id")) {
      this.code = window.location.href;
      this.payout(new Event("submit"));
    }
  }

  payout(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    document.getElementsByName("payoutCode")[0].focus();
    
    var code;
    try {
      code = new URL(this.code).searchParams.get("id");
    } catch(ex) {
      code = this.code;
    }
    
    if (code == "") { return this.toastrService.error('missing code'); }

    this.appService.payout(code)
    .then(result => {
      this.toastrService.success("You've been paid!");
      this.code = "";
      document.getElementsByName("payoutCode")[0].focus();
    })
    .catch(err => {
      this.toastrService.error(err.error || err.statusText);
      this.code = "";
      document.getElementsByName("payoutCode")[0].focus();
    });
  }
}
