import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  error: string = '';
  user: any = {
    email: '',
    password: ''
  }

  constructor(
    private router: Router,
    private appService: AppService
  ) { }

  ngOnInit() {
  }

  login() {
    if (this.user.email == '' || this.user.password == '') { return alert('missing information'); }

    this.appService.login(this.user.email, this.user.password)
    .then(result => {
      if (result.role == 99) {
        this.router.navigate(['/superadmin']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    })
    .catch(err => {
      this.error = err.error || err.statusText;
    });
  }

}
