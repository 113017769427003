import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { AppGlobals } from '../app.globals';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  error: string = "";
  password: string = "";
  passwordconfirm: string = "";

  constructor(
    private router: Router,
    private appService: AppService,
    private appGlobals: AppGlobals
  ) { }

  ngOnInit() {
  }

  reset() {
    if (this.password != this.passwordconfirm) {
      this.error = "Passwords do not match!";
      return;
    }

    let user = this.appGlobals.user;
    user.password = this.password;
    
    this.appService.updateUser(user).then(result => {
      this.router.navigate(['/dashboard']);
    }).catch(err => {
      this.error = err.error || err.statusText;
    });
  }

}
