import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { SuperadminDashboardComponent } from './superadmin-dashboard/superadmin-dashboard.component';
import { UserGuard } from './user.guard';
import { TellerGuard } from './teller.guard';
import { AdminGuard } from './admin.guard';
import { SuperadminGuard } from './superadmin.guard';
import { NotfoundComponent } from './notfound/notfound.component';
import { HomeComponent } from './home/home.component';
import { ForgotComponent } from './forgot/forgot.component';
import { DepartmentViewComponent } from './department-view/department-view.component';
import { DepartmentGuard } from './department.guard';
import { PayoutComponent } from './payout/payout.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'payout', component: PayoutComponent },
  { path: 'dashboard', component: UserDashboardComponent, canActivate: [UserGuard] },
  { path: 'superadmin', component: SuperadminDashboardComponent, canActivate: [SuperadminGuard] },
  { path: 'department/:department', component: DepartmentViewComponent, canActivate: [DepartmentGuard] },
  { path: '', component: HomeComponent },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
