import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap, switchMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';
import { ActivatedRoute, convertToParamMap } from '@angular/router';

@Component({
  selector: 'app-superadmin-dashboard',
  templateUrl: './superadmin-dashboard.component.html',
  styleUrls: ['./superadmin-dashboard.component.css']
})
export class SuperadminDashboardComponent implements OnInit {
  dept: any = {
    name: null
  }
  department: any;
  filter: any = {};
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    public appGlobals: AppGlobals,
    private appService: AppService
  ) { }

  ngOnInit() {
  }

  updateConfiguration() {
    if (!confirm("Are you sure you want to update the configuration?")) {
      this.ngOnInit();
      return;
    } else {
      this.appService.updateVolunteeringConfiguration(this.appGlobals.volunteering)
        .then(result => this.toastrService.success("Updated!"))
        .catch(err => this.toastrService.error(err.error || err.statusText));
    }
  }

  getDateRange() {
    return this.appGlobals.volunteering.dates.split("|");
  }

  createDepartment() {
    this.appService.createDepartment(this.dept.name)
      .then(result => this.toastrService.success('The department has been created. You must logout to see the new department.'))
      .catch(err => this.toastrService.error(err.error || err.statusText));
  }
}
